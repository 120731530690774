import { Box, LinearProgress, makeStyles } from '@material-ui/core'
import { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import DateRangerPicker, { ISelectDate } from 'components/DateRangerPicker'
import { IGetBreakReports, breakService } from 'services/breakService'
import { usePopup } from 'hooks/usePopup'
import ModalBreakDetail from './ModalBreakDetail'

const useStyles = makeStyles(theme => ({
  filter: {
    marginBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
    '& > div': {
      width: '100%',
      minWidth: '120px',
      maxWidth: '180px',
    },
    '& > p': {
      color: '#757575',
    },
    '& > .MuiInputBase-root .MuiSelect-selectMenu:focus': { background: 'none' },
  },
  alertNoData: {
    padding: '1rem',
    backgroundColor: '#DFEAFF',
    color: '#2E75FF',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '0.5rem',
  },
}))

const BreakReport = () => {
  const [data, setData] = useState<IGetBreakReports[]>([])
  const [loading, setLoading] = useState(false)
  const [noData, setNoData] = useState(false)
  const [openModal, setOpenModal] = useState({
    open: false,
    data: {} as IGetBreakReports,
  })
  const classes = useStyles()
  const { addPopup } = usePopup()
  const controllerRef = useRef<any>(null)

  const handleOnFilter = async ({ endDate, startDate }: ISelectDate) => {
    const date = (param: Date): string => {
      return moment(param).format('YYYY-MM-DD')
    }

    try {
      if (controllerRef.current) {
        controllerRef.current?.abort()
      }
      controllerRef.current = new AbortController()
      setLoading(true)
      const response = await breakService.getBreakReports(
        date(startDate),
        date(endDate),
        controllerRef.current,
      )
      if ((response as any)?.message === 'Nenhuma quebra encontrada para essa data') {
        setNoData(true)
        setLoading(false)
        return
      }
      setData(response as IGetBreakReports[])
      setNoData(false)
      setLoading(false)
    } catch (err: any) {
      if (err?.message === 'canceled') return
      addPopup({
        title: 'Erro ao pegar os dados de quebra',
        type: 'error',
        description: err?.message || err?.msg,
      })
      setLoading(false)
    }
  }

  const onClickBarChart = (_: MouseEvent, __: any, config: any) => {
    const valueColumn = data[config.dataPointIndex]

    if (!valueColumn) return

    setOpenModal({ open: true, data: valueColumn })
  }

  const options: ApexOptions = {
    colors: ['#567B0D'],
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      events: {
        click: onClickBarChart,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: `${data.length * 3 > 100 ? 100 : data.length * 3}%`,
        dataLabels: {
          position: 'top',
        },
        borderRadius: 1,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -30,
      style: {
        fontSize: '17px',
        colors: ['#567B0D'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    yaxis: {
      min: 0,
      max:
        data.reduce(
          (acc, current) => (current.break_quantity > acc ? current.break_quantity : acc),
          0,
        ) + 2,
      decimalsInFloat: 0,
    },
    xaxis: {
      categories: data.map(user => user.username.replace('.', ' ')),
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
    },
  }

  const series: ApexOptions['series'] = [
    {
      name: 'Quebras',
      data: data.map(user => user.break_quantity),
    },
  ]

  const getYesterday = () => {
    const date = new Date()
    const hour = date.getHours()
    date.setDate(date.getDate() - (hour >= 0 && hour < 12 ? 2 : 1))
    return date
  }

  useEffect(() => {
    handleOnFilter({ startDate: getYesterday(), endDate: getYesterday(), isUniqueDate: false })
  }, [])

  return (
    <Box>
      <LinearProgress variant="indeterminate" style={{ width: '100%', opacity: loading ? 1 : 0 }} />
      <Box display="flex" style={{ width: '100%' }}>
        <Box className={classes.filter}>
          <DateRangerPicker onSelectedDate={handleOnFilter} />
        </Box>
      </Box>
      {noData && <Box className={classes.alertNoData}>Sem dados para o período selecionado!</Box>}

      <ReactApexChart options={options} series={series} type="bar" height={350} />
      {openModal.open && (
        <ModalBreakDetail
          open={openModal.open}
          data={openModal.data}
          onClose={() => {
            setOpenModal({ open: false, data: {} as IGetBreakReports })
          }}
        />
      )}
    </Box>
  )
}

export default BreakReport
