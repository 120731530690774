import { Box, Button, ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core'
import { MdDateRange } from 'react-icons/md'
import { DateRangePicker } from 'shared'
import { useStyles } from './styles'
import { useEffect, useRef, useState } from 'react'

interface IDateRangerPicker {
  onSelectedDate: (params: ISelectDate) => void
  defaultStartDate?: Date
  defaultEndDate?: Date
  classContainer?: any
  isUniqueDate?: boolean | null | undefined
}

export type ISelectDate = {
  startDate: Date
  endDate: Date
  isUniqueDate: boolean | null | undefined
}

const getYesterday = () => {
  const date = new Date()
  const hour = date.getHours()
  date.setDate(date.getDate() - (hour >= 0 && hour < 12 ? 2 : 1))
  return date
}

const DateRangerPicker = (props: IDateRangerPicker) => {
  const [selectedSearchDateFrom, setSelectedSearchDateFrom] = useState<Date>(
    props.defaultEndDate || getYesterday,
  )
  const [selectedSearchDateTo, setSelectedSearchDateTo] = useState<Date>(
    props.defaultEndDate || getYesterday,
  )
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const anchorEl = useRef(null)
  const classes = useStyles()
  const prevOpen = useRef(open)

  const handleChangeDate = async (startDate: Date, endDate: Date) => {
    setSelectedSearchDateFrom(startDate)
    setSelectedSearchDateTo(endDate)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const onFilter = () => {
    props.onSelectedDate({
      startDate: selectedSearchDateFrom as Date,
      endDate: selectedSearchDateTo as Date,
      isUniqueDate: props?.isUniqueDate,
    })
    setOpen(false)
  }

  useEffect(() => {
    if (prevOpen.current && !open) anchorRef?.current?.focus()
    prevOpen.current = open
  }, [open])

  return (
    <>
      <Box className={props.classContainer || {}}>
        {props.isUniqueDate && (
          <Box>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              style={{ display: 'flex' }}
            >
              <Box className={classes.boxUniqueDate}>
                <MdDateRange fontSize="1.2rem" color="#757575" />
                <span>{selectedSearchDateFrom?.toLocaleDateString() ?? ''}</span>
              </Box>
            </Button>
          </Box>
        )}
        {!props.isUniqueDate && (
          <Box style={{ flex: 1 }} className={props.classContainer || {}}>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              style={{ display: 'flex' }}
            >
              <Box className={classes.boxDate}>
                <MdDateRange fontSize="1.2rem" color="#757575" />
                <span>De</span>
                <span>{selectedSearchDateFrom?.toLocaleDateString() ?? ''}</span>
              </Box>
              <Box className={classes.boxDate}>
                <MdDateRange fontSize="1.2rem" color="#757575" />
                <span>Até</span>
                <span>{selectedSearchDateTo?.toLocaleDateString() ?? ''}</span>
              </Box>
            </Button>
          </Box>
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 2 }}
          placement="top"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <DateRangePicker
                      startDate={selectedSearchDateFrom || new Date()}
                      endDate={selectedSearchDateTo || new Date()}
                      anchorEl={anchorEl.current}
                      onChange={event =>
                        handleChangeDate(
                          event.selection.startDate || new Date(),
                          event.selection.endDate || new Date(),
                        )
                      }
                    />
                    <Box
                      style={{
                        textAlign: 'end',
                        maxWidth: '20.75rem',
                        padding: '0 0.833em 1rem 0',
                      }}
                    >
                      <Button variant="contained" color="primary" onClick={() => onFilter()}>
                        Filtrar
                      </Button>
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  )
}

export default DateRangerPicker
