import {
  ButtonCancel,
  ButtonConfirm,
  Container,
  ContainerButtons,
  InfoCostumer,
  Modal,
  TextConfirmRelease,
  Title,
} from './styles'
import { Runouts } from 'interfaces/IBreakOrder'
import { Loading } from 'pages/Admin/Breaks/styles'

interface IModalConfirmPrePickingBreak {
  open: boolean
  isLoading: boolean
  handleClose: () => void
  handleConfirm: () => void
  item: Runouts
}

export const ModalConfirmPrePickingBreak = ({
  handleClose,
  item,
  handleConfirm,
  open,
  isLoading,
}: IModalConfirmPrePickingBreak) => {
  const controllerOrder = new AbortController()

  return (
    <>
      <Modal open={open}>
        <Container>
          <Loading loading={isLoading} />
          <Title>Quebra de pre picking</Title>
          <InfoCostumer>
            <p>
              N° Pedido: <strong>{item.order}</strong>
            </p>
            <p>
              Item: <strong>{item.name}</strong>
            </p>
          </InfoCostumer>
          <TextConfirmRelease>
            Será declarado quebra de pre picking pra que você possa mandar uma quantidade parcial.
          </TextConfirmRelease>
          <TextConfirmRelease>Essa ação não poderá ser desfeita, tem certeza?.</TextConfirmRelease>
          <ContainerButtons>
            <ButtonCancel onClick={handleClose} disabled={isLoading}>
              Cancelar
            </ButtonCancel>
            <ButtonConfirm onClick={handleConfirm} disabled={isLoading}>
              Confirmar
            </ButtonConfirm>
          </ContainerButtons>
        </Container>
      </Modal>
    </>
  )
}
