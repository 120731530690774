import {
  Box,
  LinearProgress,
  Tooltip,
  styled,
  Typography,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import { usePopup } from 'hooks/usePopup'
import { IResponsePackingDict, IPackingList } from 'interfaces/IPackingList'
import moment from 'moment'
import { useState, useEffect, useMemo, ChangeEvent } from 'react'
import { packingList } from 'services/packingListServices'
import { getDeliveryDate, debaunce } from 'utils'

import Calendar from '../Breaks/components/CardHistory/components/Calendar'
import { Button } from 'components/Button'
import { DownloadExcel } from 'components/ModalDownloadExcelLoading'
import { AiFillPrinter } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { RiFileDownloadLine } from 'react-icons/ri'
import { Scrollbar, Search } from 'shared'

import Accordion from './Accordion'
import { ButtonFilter, Header } from './styles'
import { ModalConfirmPrint } from 'components/pages/PackingList/ColorRoute/modalConfirmPrint'
import Pagination from 'shared/TableCustom/TablePagination'

export type IHandleEvent = {
  param: any
  type: 'filter' | 'search' | 'print'
}

export type IParams = {
  scheduledDate?: string
  searchRoute?: string
  filterByStatus?: string
  isPrinted: boolean
  page: number
  pageSize: number
}

const sortByRoutes = (a: IResponsePackingDict, b: IResponsePackingDict) => {
  if (a.route < b.route) {
    return -1
  }
  if (a.route > b.route) {
    return 1
  }
  return 0
}

const Alert = styled(Box)({
  display: 'flex',
  width: '100%',
  backgroundColor: '#FFF3CD',
  border: '1px solid #FEE59B',
  padding: '0.6rem 0 0.6rem 1rem',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#664D03 ',
  fontWeight: 500,
  margin: '1rem 0',
})

const PackingList = () => {
  const currentDeliveryDate = (() => {
    const { year, month, day } = getDeliveryDate()
    const date = new Date(`${year}-${month}-${day}`)
    date.setHours(date.getHours() + 3)
    return date
  })()

  const { addPopup } = usePopup()
  const [routes, setRoutes] = useState<IResponsePackingDict[]>([])
  const [params, setParams] = useState<IParams>({
    isPrinted: false,
    page: 1,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(currentDeliveryDate)
  const [openModalDownloadExcel, setOpenModalDownloadExcel] = useState(false)
  const [isloadingPrint, setIsLoadingPrint] = useState(false)
  const [openModalAlert, setOpenModealAlert] = useState({
    open: false,
    route: 0,
  })
  const [tab, setTab] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const getRoutes = async (params: IParams) => {
    setLoading(true)
    try {
      const response = await packingList.getRoutes(params)
      setTotalCount(response.totalCount)

      setRoutes(
        Object.entries(response.results)
          .map(([_, value]) => value)
          .sort(sortByRoutes),
      )
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar rotas',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoading(false)
    }
  }

  const handlePrintRoute = async (route: number) => {
    setLoading(true)
    try {
      await packingList.printSingleRoute({ route })
      addPopup({
        type: 'success',
        title: `Impressão da rota ${route} realizada com sucesso!`,
      })
    } catch (err) {
      addPopup({
        type: 'error',
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro, contate o administrador',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleEvent = async ({ param, type }: IHandleEvent) => {
    if (type === 'print') {
      handlePrintRoute(param)
      setRoutes(state =>
        state.map(item => (item.route === param ? { ...item, printed: true } : item)),
      )
    }
  }

  const handleFilterRoutes = (status: string) => {
    if (status === params.filterByStatus) {
      const updatedParams = {
        ...params,
        page: 1,
        filterByStatus: '',
      }
      setParams(updatedParams)
      return
    }

    const updatedParams = {
      ...params,
      page: 1,
      filterByStatus: status,
    }
    setParams(updatedParams)
  }

  const onSelectedDate = (newDate: Date) => {
    const formattedDate = moment(newDate).format('YYYY-MM-DD')
    const updatedParams = {
      ...params,
      scheduledDate: formattedDate,
    }
    setParams(updatedParams)
  }

  const onSelectedPrintStatus = (isPrinted: boolean) => {
    const updatedParams = {
      ...params,
      isPrinted,
    }
    setParams(updatedParams)
  }

  const handleSearch = (search: string) => {
    const updatedParams = {
      ...params,
      page: 1,
      searchRoute: search,
    }
    setParams(updatedParams)
  }

  const handlePaginationChange = (params: IParams) => {
    const updatedParams = {
      ...params,
      pageSize: params.pageSize,
      page: params.page,
    }
    setParams(updatedParams)
  }

  const searchRoute = debaunce({ fn: handleSearch, delay: 200 })

  const toggleDownloadExcelBoxesRelation = () => {
    setOpenModalDownloadExcel(state => !state)
  }

  const handleManyPrints = async () => {
    setIsLoadingPrint(true)
    try {
      const routeData = routes
        .filter(({ route }) => !!JSON.parse(route.toString()))
        .map(route => ({
          route: route.route,
          driverName: route.orders[0]?.order_delivery_details?.driver,
          totalBoxes: route.orders.reduce((acc: number, order: IPackingList) => {
            const boxes =
              {
                true: order.corrected_boxes_number || 0,
                false: order.loading_boxes_number || 0,
              }[String(order.skipped)] ?? 0
            return acc + boxes
          }, 0),
        }))
      await packingList.printManyRoutes(routeData)

      addPopup({
        type: 'success',
        title: 'Impressão enviada! 🤠',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Deu erro pra mandar a impressão 😞',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoadingPrint(false)
    }
  }

  const handleOpenModalPrintWithoutScanner = (route: number) => {
    setOpenModealAlert({ open: true, route })
  }

  const handleCloseModalPrintWithoutScanner = () => {
    setOpenModealAlert({ open: false, route: 0 })
  }

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  useEffect(() => {
    getRoutes(params)
  }, [params])

  useEffect(() => {
    if (tab === 0) {
      onSelectedPrintStatus(false)
    }
    if (tab === 1) {
      onSelectedPrintStatus(true)
    }
  }, [tab])

  return (
    <ContainerPage>
      <TitlePage>Romaneios 🚛</TitlePage>
      <Box
        style={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          gap: '0.5rem',
          justifyContent: 'space-between',
          marginTop: '2rem',
        }}
      >
        <Box
          style={{
            width: isSmallScreen ? '100%' : 'auto',
            display: isSmallScreen ? 'flex' : 'block',
            justifyContent: isSmallScreen ? 'center' : 'unset',
            alignItems: isSmallScreen ? 'center' : 'unset',
            margin: isSmallScreen ? '0 auto' : 'unset',
          }}
        >
          <Tooltip title="Selecione o dia que as rotas serão entregues" arrow placement="top">
            <div>
              <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                onSelectedDate={onSelectedDate}
                baseFilter="deliveryDate"
              />
            </div>
          </Tooltip>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            gap: '0.5rem',
            alignItems: isSmallScreen ? 'stretch' : 'center',
            width: isSmallScreen ? '100%' : 'auto',
          }}
        >
          <Button
            onClick={() => handleManyPrints()}
            variant="contained"
            startIcon={<AiFillPrinter size={20} />}
            disabled={isloadingPrint}
            style={{ marginRight: isSmallScreen ? '0' : '1rem' }}
          >
            {isloadingPrint ? 'Imprimindo' : 'Imprimir todas'}
          </Button>
          <Button
            onClick={toggleDownloadExcelBoxesRelation}
            variant="contained"
            startIcon={<RiFileDownloadLine size={20} />}
          >
            Baixar planilha
          </Button>
        </Box>
      </Box>
      {!moment(currentDeliveryDate).isSame(selectedDate) && (
        <Alert>Visualizando rotas que não são de hoje.</Alert>
      )}
      <Tabs value={tab} onChange={handleTabChange} centered style={{ marginTop: '1rem' }}>
        <Tab label="Aguardando Impressão" />s
        <Tab label="Impressos" />
      </Tabs>
      <LinearProgress
        style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem', opacity: loading ? 1 : 0 }}
      />
      <Header
        style={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          gap: isSmallScreen ? '1rem' : '0',
        }}
      >
        <Search
          placeholder="Qual rota?"
          style={{
            width: '100%',
            maxWidth: '165px',
          }}
          onChange={e => {
            searchRoute(e.target.value)
          }}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            width: '100%',
            justifyContent: isSmallScreen ? 'flex-start' : '',
            marginLeft: !isSmallScreen ? '1rem' : '',
          }}
        >
          {!isSmallScreen && <Typography>Filtrar:</Typography>}
          <ButtonFilter
            colorIcon="#FFC107"
            onClick={() => handleFilterRoutes('pending')}
            isSelected={params.filterByStatus === 'pending'}
          >
            <BsCircleFill /> <span>Pendente</span>
          </ButtonFilter>
          <ButtonFilter
            colorIcon="#2E75FF"
            onClick={() => handleFilterRoutes('loading')}
            isSelected={params.filterByStatus === 'loading'}
          >
            <BsCircleFill />
            <span>Carregando</span>
          </ButtonFilter>
          <ButtonFilter
            colorIcon="#89BD23"
            onClick={() => handleFilterRoutes('loaded')}
            isSelected={params.filterByStatus === 'loaded'}
          >
            <BsCircleFill />
            <span>Carregado</span>
          </ButtonFilter>
        </Box>
      </Header>

      <div>
        <Box display="flex" flexDirection="column" style={{ gap: '12px', marginTop: '20px' }}>
          {routes.map(item => {
            return (
              <Accordion
                item={item}
                handleEvent={handleEvent}
                key={item.route}
                handlePrintWithoutScanner={() => handleOpenModalPrintWithoutScanner(item.route)}
                isSmallScreen={isSmallScreen}
              />
            )
          })}
        </Box>
      </div>
      <Scrollbar>
        <Pagination
          page={params.page}
          count={totalCount}
          onChange={(_, newPage: number) => handlePaginationChange({ ...params, page: newPage })}
          rowsPerPage={params.pageSize}
          handleRowsPerPageChange={newPageSize =>
            handlePaginationChange({ ...params, pageSize: newPageSize })
          }
        />
      </Scrollbar>

      <DownloadExcel
        isOpen={openModalDownloadExcel}
        handleCloseModal={toggleDownloadExcelBoxesRelation}
        selectedDate={selectedDate}
      />
      <ModalConfirmPrint
        route={openModalAlert.route}
        open={openModalAlert.open}
        handleClose={handleCloseModalPrintWithoutScanner}
      />
    </ContainerPage>
  )
}

export default PackingList
