import {
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { ColorRoute } from 'components/pages/PackingList/ColorRoute'
import { useMemo, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { MdExpandMore } from 'react-icons/md'
import { animated } from 'react-spring'
import { mapTagColor } from 'utils/mapTagColor'

import { IHandleEvent } from '../index'
import { AccordionStyled, ButtonPrinter, ButtonPrinterWithoutScanner, StrongHeader } from './styles'
import { IPackingList, IResponsePackingDict } from 'interfaces/IPackingList'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { enRules } from 'interfaces/IUser'

interface Props {
  item: IResponsePackingDict
  handleEvent: (param: IHandleEvent) => void
  handlePrintWithoutScanner: () => void
  isSmallScreen: boolean
}
const Accordion = ({ item, handleEvent, handlePrintWithoutScanner, isSmallScreen }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const { user } = useSelector<IState, IState>(state => state)
  const { userPermissions } = user
  const readBoxes = useMemo(() => {
    return item.orders.reduce((acc, order) => {
      const boxes = Number(
        order?.adjusted_boxes_number_conveyor ||
          order?.corrected_boxes_number ||
          order.loading_boxes_number,
      )
      return acc + boxes
    }, 0)
  }, [item])

  const getTotalOrderBoxes = (order: IPackingList) => {
    if (order.skipped) {
      return `${order.corrected_boxes_number} / ${order.corrected_boxes_number} (Foram corrigidas)`
    }
    if (order.adjusted_boxes_number) {
      return `${order.loading_boxes_number || 0} / ${
        order.adjusted_boxes_number
      } (Lidas / Ajustadas)`
    }
    if (order.boxes_by_conferencer) {
      return `${order.loading_boxes_number || 0} / ${
        order.boxes_by_conferencer
      } (Lidas / Corrigidas)`
    }
    if (order.conference_boxes_number === 0) {
      return 'Finalizado manualmente'
    }
    return `${order.loading_boxes_number || 0} / ${
      order.conference_boxes_number
    } (Lidas / Separadas)`
  }

  const refactTimeToDelivery = (time: string) => {
    const formattedTime = time?.split(':').slice(0, 2).join(':')
    return formattedTime
  }

  return (
    <>
      <animated.div>
        <AccordionStyled expanded={expanded} key={item.route}>
          <AccordionSummary
            expandIcon={
              <MdExpandMore
                onClick={() => {
                  setExpanded(state => !state)
                }}
                style={{
                  position: 'absolute',
                  right: 0,
                }}
              />
            }
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div>
              <ColorRoute colorRoute={mapTagColor(item.colorRoute)} />
            </div>
            <StrongHeader>
              <span>
                {!item.route && !isSmallScreen && <>Sem rota / {item.colorRoute} </>}
                {!item.route && isSmallScreen && <>Sem rota </>}
                {!!item.route && !isSmallScreen && (
                  <>
                    Rota {item.route} / {item.colorRoute}
                  </>
                )}
                {!!item.route && isSmallScreen && <>Rota {item.route}</>}
              </span>
            </StrongHeader>
            {!isSmallScreen && (
              <>
                <StrongHeader>
                  <span>{item.orders[0]?.order_delivery_details?.driver ?? 'Sem motorista'}</span>
                </StrongHeader>
                <StrongHeader>
                  <span>
                    {item.quantityOrders} {item.quantityOrders === 1 ? 'Pedido' : 'Pedidos'}
                  </span>
                </StrongHeader>
                <StrongHeader>
                  <span>
                    {readBoxes} {readBoxes === 1 ? 'Caixa' : 'Caixas'}
                  </span>
                </StrongHeader>
              </>
            )}
            <StrongHeader>
              <span
                style={{
                  display: isSmallScreen ? 'inline-flex' : 'flex',
                  alignItems: isSmallScreen ? 'inline-center' : '',
                  marginLeft: isSmallScreen ? '1rem' : '0rem',
                }}
              >
                {item.status === 'pending' && (
                  <>
                    <BsCircleFill style={{ marginRight: '0.5rem' }} color="#FFC107" /> Pendente
                  </>
                )}
                {item.status === 'loading' && (
                  <>
                    <BsCircleFill style={{ marginRight: '0.5rem' }} color="#2E75FF" /> Carregando
                  </>
                )}
                {item.status === 'loaded' && (
                  <>
                    <BsCircleFill style={{ marginRight: '0.5rem' }} color="#89BD23" /> Carregado
                  </>
                )}
              </span>
            </StrongHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title={
                  item.separateFinish === false
                    ? 'Aguarde o carregamento dessa rota.'
                    : 'Imprimir considerando o carregamento.'
                }
              >
                <ButtonPrinter
                  onClick={() => {
                    handleEvent({ type: 'print', param: item.route })
                  }}
                  disabled={['pending', 'loading'].includes(item.status)}
                  style={{
                    marginRight: isSmallScreen ? '-3rem' : '',
                  }}
                >
                  <AiFillPrinter style={{ fontSize: '30px' }} />
                </ButtonPrinter>
              </Tooltip>
              {['pending', 'loading'].includes(item.status) &&
                userPermissions.includes(enRules.SeparationPrintShipmentWithoutScanner) && (
                  <Tooltip
                    title={
                      item.separateFinish === false
                        ? 'Aguarde a separação dos pedidos dessa rota.'
                        : 'Imprimir sem considerar o carregamento.'
                    }
                  >
                    <div>
                      <ButtonPrinterWithoutScanner
                        onClick={() => {
                          handlePrintWithoutScanner()
                        }}
                        disabled={item.separateFinish === false}
                      >
                        <AiFillPrinter style={{ fontSize: '30px' }} />
                      </ButtonPrinterWithoutScanner>
                    </div>
                  </Tooltip>
                )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pedido</TableCell>
                  {!isSmallScreen && <TableCell>Cliente</TableCell>}
                  {!isSmallScreen && <TableCell>Bairro</TableCell>}
                  {!isSmallScreen && <TableCell>Endereço</TableCell>}
                  <TableCell>NF</TableCell>
                  {!isSmallScreen && <TableCell>Horário</TableCell>}
                  <TableCell>Caixas</TableCell>
                  {!isSmallScreen && <TableCell>Obs</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {item.orders.map(order => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_id}</TableCell>
                    {!isSmallScreen && <TableCell>{order.client_name_or_trade}</TableCell>}
                    {!isSmallScreen && (
                      <TableCell>{order.order_delivery_details?.neighborhood}</TableCell>
                    )}
                    {!isSmallScreen && (
                      <TableCell>
                        {order.order_delivery_details?.shipping_street_and_number}
                      </TableCell>
                    )}
                    <TableCell>{order.order_delivery_details?.bill_of_sale_id}</TableCell>
                    {!isSmallScreen && (
                      <TableCell>
                        {refactTimeToDelivery(order.order_delivery_details?.scheduled_time_from)} -{' '}
                        {refactTimeToDelivery(order.order_delivery_details?.scheduled_time_to)}
                      </TableCell>
                    )}
                    <TableCell>{getTotalOrderBoxes(order)}</TableCell>
                    {!isSmallScreen && (
                      <TableCell>{order.order_delivery_details?.observations}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </AccordionStyled>
      </animated.div>
    </>
  )
}

export default Accordion
